<!--
 * @version: 1.0
 * @Date: 2023-04-25 17:06:58
 * @LastEditors: 杨慢慢
-->
<template>
  <a-form-item label="选项设置" class="options" required>
    <draggable
      :list="config.options"
      handle=".rank"
      :sort="true"
      :disabled="config?.allDisabled"
      :animation="300"
      item-key="name"
    >
      <template #item="{ index }">
        <div class="rank-item">
          <a-input
            v-model:value="config.options[index]"
            placeholder="请设置选项值"
            clearable
            :maxLength="10"
            :disabled="config?.allDisabled"
          >
            <template #suffix>
              <span class="del" @click="delOptions(index)">
                <i class="iconfont icon-delete"></i>
              </span>
              <span class="rank">
                <i class="iconfont icon-sort"></i>
              </span>
            </template>
          </a-input>
        </div>
      </template>
    </draggable>
    <div :class="['add-btn', config?.allDisabled && 'btn-Disabled']" @click="addOptions">
      <PlusOutlined />
      添加选项
    </div>
  </a-form-item>
</template>
<script setup>
import draggable from 'vuedraggable'
import { DragOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { defineProps } from 'vue'
import { cmsNotice } from '@/utils/utils'
const { config } = defineProps(['config'])
// 添加选项
const addOptions = () => {
  if (config.allDisabled) return

  const len = config.options.length
  if (len === 20) return cmsNotice('warning', '最多支持添加20个选项')
  config.options.push('选项' + (len + 1))
}
// 删除选项
const delOptions = index => {
  if (config.allDisabled) return
  const len = config.options.length
  if (len === 1) return cmsNotice('warning', '至少保留一个选项')
  config.options.splice(index, 1)
}
</script>

<style lang="less" scoped>
.ant-input-group-wrapper {
  margin-bottom: 8px;
}
.add-btn {
  border: 1px dashed #d9d9d9;
  height: 32px;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #333;
  cursor: pointer;
}
.rank-item {
  margin-bottom: 12px;
}

.rank-item .iconfont {
  color: #999;
}
.rank {
  cursor: move;
  margin-left: 16px;
}
.del {
  cursor: pointer;
}
:deep(.ant-input-group-addon) {
  background: none;
}

.btn-Disabled {
  cursor: no-drop;

  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
// :deep(.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child) {
//   border-right: none;
// }
</style>
